import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import ImageText from '../components/pageModules/modules/imageText';
import { useTracking } from '../components/analytics';

// Components
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import ContactForm from '@components/portals/contact/contactForm';

const TicketingPageTemplate = ({ data: { page } }) => {
  const { pageModules, seo, config } = page || {};
  const { track } = useTracking();

  useEffect(() => {
    try {
      var klaviyo = klaviyo || [];
      var page = window.location.href;
      klaviyo.push([
        'track',
        'Viewed Page',
        { url: 'https://www.chambermusic.co.nz/ticketing' },
      ]);
    } catch (err) {
      console.log('Error occurred adding klaviyo script: ' + err);
    }
  }, []);

  useEffect(() => {
    track('Viewed Page', {
      behaviour: true,
    });
  }, []);

  return (
    <Layout config={config}>
      <PageMeta {...seo} />
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  );
};

export default TicketingPageTemplate;

export const pageQuery = graphql`
  query ticketingPage {
    page: sanityTicketingPage {
      seo {
        ...SEO
      }
      config: pageConfig {
        reverseHeader
        unpinnedHeader
      }
      pageModules: modules {
        ...RestrictedPageModules
      }
    }
  }
`;
