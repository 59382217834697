import React from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';

import ModuleWrapper from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';
import SimplePortableText from '@components/sanity/simplePortableText';
import { useSubscriptionOpen, useUpdateSubscriptionStep } from '@lib/context';

const circleVars = {
  initial: {
    scale: 0,
  },
  animate: {
    scale: [0, 1.1, 1],
    transition: {
      duration: 2,
      ease: 'easeInOut',
    },
  },
};

const ImageText = ({
  config,
  key,
  text,
  image,
  layout,
  accentColor,
  className,
  portrait,
  hasTicketingButton,
  ticketingButtonText,
}) => {
  const [animate, setAnimate] = React.useState(false);
  let buttonText;
  switch (ticketingButtonText) {
    case 'season':
      buttonText = 'Full Season Subscription';
      break;
    case 'single':
      buttonText = 'Book Single Tickets';
      break;
    case 'special':
      buttonText = 'Book Special Concert Tickets';
      break;
  }
  const toggleSubscriptionOpen = useSubscriptionOpen();
  const updateSelecetdStep = useUpdateSubscriptionStep();

  return (
    <ModuleWrapper
      {...config}
      key={key}
      setAnimate={setAnimate}
      className={`${className} ${
        !portrait && 'gap-x-24 xl:gap-x-32'
      } flex flex-col-reverse lg:grid px-gutter gap-y-10 lg:grid-cols-2 items-center mb-16`}>
      {/* Image */}
      <div
        className={cx('relative lg:row-start-1 w-full', {
          'col-start-1': layout === 'left' || !layout,
          'col-start-2': layout === 'right',
          'w-2/3': portrait,
          'ml-auto': portrait && layout === 'right',
        })}>
        <div
          className={cx('relative w-full overflow-hidden', {
            'aspect-w-1 aspect-h-1 circle': !portrait,
            'aspect-w-2 aspect-h-3 rounded-full': portrait,
          })}>
          <SanityImage
            image={image}
            className='transition-all ease-in-out duration-500 absolute top-0 left-0 w-full h-full object-cover'
          />
        </div>
        <motion.div
          key={animate}
          variants={circleVars}
          initial='initial'
          animate={animate ? 'animate' : 'initial'}
          style={{ borderColor: accentColor?.value }}
          className={cx(
            'absolute border-2 md:border-4 rounded-full pointer-events-none',
            {
              '-bottom-16': !portrait,
              'right-0 lg:-right-8':
                (layout === 'left' || !layout) && !portrait,
              'right-0 lg:-right-24 -bottom-16 lg:-bottom-24':
                (layout === 'left' || !layout) && portrait,
              'left-0 lg:-left-8': layout === 'right' && !portrait,
              'left-0 lg:-left-24 -bottom-16 lg:-bottom-24':
                layout === 'right' && portrait,
              'w-1/2 h-1/2': !portrait,
              'w-1/2 h-1/3 lg:w-[332px] lg:h-[332px]': portrait,
            },
          )}
        />
      </div>
      {/* Text */}
      <div
        className={cx('row-start-1', {
          'col-start-2': layout === 'left' || !layout,
          'col-start-1': layout === 'right',
        })}>
        <SimplePortableText text={text} />
        {hasTicketingButton && (
          <button
            className={'mt-5 btn-standard bg-teal  bg-opacity-10 text-teal'}
            onClick={() => {
              updateSelecetdStep(ticketingButtonText);
              toggleSubscriptionOpen(true);
            }}>
            {buttonText}
          </button>
        )}
      </div>
    </ModuleWrapper>
  );
};

export default ImageText;
