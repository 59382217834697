import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import SimplePortableText from '../../sanity/simplePortableText';

const FormInner = () => {
  const [submitted, setSubmitted] = React.useState(false);

  const [state, setState] = React.useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  });

  const formData = {
    fullName: state.fullName,
    email: state.email,
    phone: state.phone,
    message: state.message,
  };

  function handleSubmit(e) {
    e.preventDefault();
    let myForm = document.getElementById('contactForm');
    let data = new FormData(myForm);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(data).toString(),
    })
      .then(() => console.log('Form successfully submitted'))
      .then(() => setSubmitted(true))
      .catch((error) => alert(error));

    fetch('/.netlify/functions/sendmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    }).then((res) => res.json());
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {submitted ? (
        <motion.div
          key={submitted}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className='richH3 text-teal'>
          Thank you, we will be in touch soon.
        </motion.div>
      ) : (
        <motion.form
          key={submitted}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          id='contactForm'
          onSubmit={(e) => handleSubmit(e)}
          name='contact'
          method='POST'
          data-netlify='true'>
          <input type='hidden' name='form-name' value='contact' />
          <input
            required
            className='styledInput'
            placeholder='Full Name'
            name='fullName'
            onChange={(e) =>
              setState((prev) => {
                return { ...prev, fullName: e.target.value };
              })
            }
          />
          <input
            required
            className='styledInput'
            placeholder='Email Address'
            name='email'
            onChange={(e) =>
              setState((prev) => {
                return { ...prev, email: e.target.value };
              })
            }
          />
          <input
            required
            className='styledInput'
            placeholder='Phone Number'
            name='phone'
            onChange={(e) =>
              setState((prev) => {
                return { ...prev, phone: e.target.value };
              })
            }
          />
          <textarea
            required
            rows='10'
            className='styledInput px-0'
            placeholder='Message'
            name='message'
            onChange={(e) =>
              setState((prev) => {
                return { ...prev, message: e.target.value };
              })
            }
          />
          <button
            className='btn-standard bg-teal bg-opacity-10 ml-auto block text-teal md:-mt-6'
            type='submit'>
            Submit
          </button>
        </motion.form>
      )}
    </AnimatePresence>
  );
};

const ContactForm = ({ formText }) => {
  return (
    <div className='relative z-10 pt-space-m grid grid-cols-14 gap-y-8'>
      <div className='col-start-2 col-end-14 lg:col-end-6'>
        <SimplePortableText text={formText} />
      </div>
      <div className='col-start-2 lg:col-start-7 col-end-14'>
        <FormInner />
      </div>
    </div>
  );
};

export default ContactForm;
